
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import { ElForm } from 'element-ui/types/form'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private searchForm = {
		waybill: '',
		user_code: '',
		status: '',
		work_order_status: '',
		cate: ''
	}
	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	private form = {
		item: {
			status: 2,
			id: 0
		},
		visible: false,
		process_method: 1,
		express_company: '',
		express_number: '',
		remark: ''
	}
	private refreshLoading = false

	private processForm = {
		process_method: [{ required: true, message: '请选择', trigger: 'change' }],
		express_company: [
			{ required: true, message: '请输入或者选择快递公司', trigger: 'blur' }
		],
		express_number: [
			{
				required: true,
				message: '请输入快递单号',
				trigger: 'blur'
			}
		],
		remark: [{ required: true, message: '请输入处理结果', trigger: 'blur' }]
	}

	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}

	formatCode(num: string) {
		if (!num) {
			return '-'
		}
		return 'E' + num.substr(-5)
	}

	formatStatus(status: number) {
		if (status == 0) {
			return {
				label: '已删除',
				type: 'danger'
			}
		}
		if (status == 1) {
			return {
				label: '已处理',
				type: 'success'
			}
		}
		if (status == 2) {
			return {
				label: '已创建',
				type: 'primary'
			}
		}
		if (status == 3) {
			return {
				label: '待作业',
				type: 'warning'
			}
		}
		if (status == 4) {
			return {
				label: '待退件',
				type: 'warning'
			}
		}
		return {
			label: '',
			type: 'info'
		}
	}
	formatWorkOrderStatus(status: number) {
		if (status == 0) {
			return {
				label: '已删除',
				type: 'danger'
			}
		}
		if (status == 1) {
			return {
				label: '已完成',
				type: 'success'
			}
		}
		if (status == 2) {
			return {
				label: '待处理',
				type: 'info'
			}
		}
		if (status == 3) {
			return {
				label: '进行中',
				type: 'primary'
			}
		}
		if (status == 4) {
			return {
				label: '待确认',
				type: 'warning'
			}
		}
		return {
			label: '',
			type: 'info'
		}
	}
	/**
	 * 常用快递公司
	 */
	private logisticsList = [
		{
			key: 'SF',
			name: '顺丰快递'
		},
		{
			key: 'ZTO',
			name: '中通快递'
		},
		{
			key: 'YTO',
			name: '圆通快递'
		},
		{
			key: 'STO',
			name: '申通快递'
		},
		{
			key: 'YUNDA',
			name: '韵达快递'
		},
		{
			key: 'EMS',
			name: 'EMS'
		},
		{
			key: 'GTO',
			name: '国通快递'
		},
		{
			key: 'HTKY',
			name: '百世快递'
		},
		{
			key: 'YZPY',
			name: '邮政快递包裹'
		},
		{
			key: 'HHTT',
			name: '天天快递'
		},
		{
			key: 'JD',
			name: '京东快递'
		},
		{
			key: 'UC',
			name: '优速快递'
		},
		{
			key: 'DBL',
			name: '德邦快递'
		},
		{
			key: 'ZJS',
			name: '宅急送'
		},
		{
			key: 'JT',
			name: '极兔快递'
		}
	]
	private list: any = []
	created() {
		const { size, current_page }: any = this.$route.query
		this.pageConfig.size = Number(size || 20)
		this.pageConfig.current_page = Number(current_page || 1)
		this.search(true)
		this.syncWaybillException(false)
	}
	async processWayBill({ row, $index }: any) {
		this.form.item = { ...row }
		this.form.visible = true
		this.form.process_method = row.status == 4 ? 2 : 1
	}
	resetSearch() {
		this.searchForm.waybill = ''
		this.search(true)
	}
	viewTeambitionDetail(row: any) {
		const { workOrder } = row
		const { link } = workOrder
		window.open(link, '_blank')
	}

	async saveUserCode(row: any) {
		console.log(row)
		if (!row.user_code || row.user_code == row.user.code) {
			row.is_edited = false
			return
		}
		const { user_code, id } = row
		const { data } = await this.$axios
			.post(`/v1/jobline/waybill/exception/${id}/update-user-code`, {
				user_code
			})
			.catch(e => {
				return { data: null }
			})
		if (!data) return
		this.$message.success('保存成功')
		row.is_edited = false
		row.user.code = user_code
		this.search(false)
	}

	cancelEdit({ row, $index }: any) {
		row.is_edited = false
	}
	printBarCode(packageNum: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		window.open(
			`${baseUrl}v1/jobline/generate/exception-waybill/${packageNum}`,
			'_blank'
		)
	}
	async outShelf({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认要下架该包裹？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning'
		}).catch(() => false)
		if (!isConfirm) return false
		const { data } = await this.$axios
			.post('/v1/jobline/waybill/exception/shelf/out', {
				waybill: row.waybill_num
			})
			.catch(e => {
				return {
					data: null
				}
			})
		if (data) {
			row.status_shelf = 0
		}
	}
	async search(reset?: boolean) {
		if (reset) {
			this.pageConfig.current_page = 1
		}
		const {
			waybill,
			user_code,
			status,
			work_order_status,
			cate
		} = this.searchForm
		const { size, current_page } = this.pageConfig

		const { data } = await this.$axios
			.get('/v1/jobline/waybill/exception', {
				params: {
					user_code,
					waybill,
					status,
					cate,
					work_order_status,
					page_size: size,
					page_index: current_page
				}
			})
			.catch(e => {
				return { data: null }
			})
		if (data) {
			data.list.forEach((item: any) => {
				item.is_edited = false
				item.user_code = item.user.code
			})
		}
		this.list = data.list
		this.pageConfig.total = data.page.total
	}
	/**
	 * 查询异常订单是否已下单
	 */
	async syncWaybillException(loading: boolean) {
		this.refreshLoading = loading
		const { data } = await this.$axios
			.get('/v1/jobline/waybill/exception/sync')
			.catch(e => {
				return { data: null }
			}) // 同步异常
		setTimeout(() => {
			this.refreshLoading = false
			if (loading) {
				this.search(false)
			}
		}, 2000)
	}
	/**
	 * 标记为已删除
	 */
	async remove({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning'
		}).catch(() => false)
		if (!isConfirm) return false
		const { data } = await this.$axios
			.delete(`/v1/jobline/waybill/exception/${row.id}`)
			.catch((e: any) => {
				return { data: null }
			})
		if (data) {
			this.$message.success('下架成功')
			this.list.splice($index, 1)
		}
	}
	async handleProcess() {
		console.log(this.form)
		const isConfirm = await this.$confirm('确认要提交吗？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning'
		}).catch(() => false)
		if (!isConfirm) return false
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return false
		const {
			item,
			process_method,
			express_company,
			express_number,
			remark
		} = this.form
		console.log(this.form)
		const { data } = await this.$axios.post(
			'/v1/jobline/waybill/exception/process',
			{
				id: item.id,
				process_method,
				express_company,
				express_number,
				remark
			}
		)
		if (data) {
			this.form.remark = ''
			this.form.express_company = ''
			this.form.express_number = ''
			this.form.visible = false
			this.search(false)
		}
	}
}
