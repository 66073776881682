var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("异常件")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { attrs: { gutter: 30, span: 24 } }, [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "入仓/包裹/工单单号",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search(true)
                          },
                        },
                        model: {
                          value: _vm.searchForm.waybill,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "waybill",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.waybill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "用户编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search(true)
                          },
                        },
                        model: {
                          value: _vm.searchForm.user_code,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "user_code",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.user_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择状态", clearable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.search(true)
                            },
                          },
                          model: {
                            value: _vm.searchForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "status", $$v)
                            },
                            expression: "searchForm.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "待退件", value: "4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "待作业", value: "3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已创建", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已处理", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分类", clearable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.search(true)
                            },
                          },
                          model: {
                            value: _vm.searchForm.cate,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "cate", $$v)
                            },
                            expression: "searchForm.cate",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未下单", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "需退回", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "其他", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择工单状态",
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.search(true)
                            },
                          },
                          model: {
                            value: _vm.searchForm.work_order_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "work_order_status", $$v)
                            },
                            expression: "searchForm.work_order_status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "待确认", value: "4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "进行中", value: "3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "待处理", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已完成", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search(true)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("z-button", { on: { click: _vm.resetSearch } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    underline: false,
                    href: "/warehouse/inbound/exception-input",
                  },
                },
                [
                  _c(
                    "z-button",
                    { attrs: { type: "primary", size: "medium", plain: "" } },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_addbox" },
                          }),
                        ]
                      ),
                      _vm._v("录入"),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-link",
                {
                  attrs: {
                    underline: false,
                    href: "/warehouse/inbound/exception-in-shelf",
                  },
                },
                [
                  _c(
                    "z-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        plain: "",
                        icon: "el-icon-upload2",
                      },
                    },
                    [_vm._v("上架")]
                  ),
                ],
                1
              ),
              _c(
                "z-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    plain: "",
                    icon: "el-icon-refresh",
                    loading: _vm.refreshLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.syncWaybillException(true)
                    },
                  },
                },
                [_vm._v("同步订单")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "waybill", label: "入仓单号", width: "230" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  target: "_blank",
                                  href: `/dashboard?logistics_code=${scope.row.waybill}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.waybill))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf_code",
                      label: "货架号",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.status_shelf == 1
                                    ? scope.row.shelf_code || "-"
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "waybill_num",
                      label: "编号",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatCode(scope.row.waybill_num)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scaned",
                      label: "入仓时间",
                      width: "180",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatDate")(scope.row.scaned)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "关联订单" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "package", label: "单号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !!scope.row.package.package_num
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                          target: "_blank",
                                          href: `/dashboard?logistics_code=${scope.row.package.package_num}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.package.package_num || "-"
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "user",
                          label: "用户编码",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !!scope.row.user.code && !scope.row.is_edited
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false,
                                          target: "_blank",
                                          href: `/user/packages?user_code=${scope.row.user.code}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.user.code || "-")
                                        ),
                                      ]
                                    )
                                  : scope.row.is_edited
                                  ? _c("el-input", {
                                      attrs: {
                                        placeholder: "用户编码",
                                        autosize: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.saveUserCode(scope.row)
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.saveUserCode(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.user_code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "user_code",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.user_code",
                                      },
                                    })
                                  : _c("span", [_vm._v("-")]),
                                !scope.row.package.package_num &&
                                !scope.row.is_edited
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                        staticStyle: { "margin-left": "10px" },
                                        on: {
                                          click: function ($event) {
                                            scope.row.is_edited = true
                                          },
                                        },
                                      }),
                                    ])
                                  : _c("span"),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "ordered",
                          label: "下单时间",
                          width: "180",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.ordered)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "备注", width: "130" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "timeline",
                      label: "处理进度",
                      align: "center",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.timeline
                              ? _c(
                                  "el-popover",
                                  { attrs: { placement: "right" } },
                                  [
                                    _c(
                                      "el-timeline",
                                      _vm._l(
                                        scope.row.timeline,
                                        function (item, index) {
                                          return _c(
                                            "el-timeline-item",
                                            {
                                              key: index,
                                              attrs: {
                                                timestamp: _vm._f("formatDate")(
                                                  item.created
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.content) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_c("p", [_vm._v("查看")])]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "退件信息",
                      prop: "return_info",
                      width: "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.return_info
                              ? _c(
                                  "el-popover",
                                  { attrs: { placement: "right" } },
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _vm._v(
                                          "收件人：" +
                                            _vm._s(
                                              scope.row.return_info.recipient
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "电话：" +
                                            _vm._s(scope.row.return_info.mobile)
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "地址： " +
                                            _vm._s(
                                              scope.row.return_info.address
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "快递公司：" +
                                            _vm._s(
                                              scope.row.return_info
                                                .express_company
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "快递单号：" +
                                            _vm._s(
                                              scope.row.return_info
                                                .express_number
                                            )
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "备注：" +
                                            _vm._s(
                                              scope.row.return_info.remark ||
                                                "-"
                                            )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_c("p", [_vm._v("查看")])]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "分类",
                      align: "center",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.cate == 1
                              ? _c("span", [_vm._v("未下单")])
                              : _vm._e(),
                            scope.row.cate == 2
                              ? _c("span", [_vm._v("需退回")])
                              : _vm._e(),
                            scope.row.cate == 3
                              ? _c("span", [_vm._v("其他")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      align: "center",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "mini",
                                  type: _vm.formatStatus(scope.row.status).type,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatStatus(scope.row.status).label
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "status",
                        label: "工单状态",
                        align: "center",
                        width: "140",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.workOrder && scope.row.workOrder.status
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: _vm.formatWorkOrderStatus(
                                              scope.row.workOrder.status
                                            ).type,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatWorkOrderStatus(
                                                scope.row.workOrder.status
                                              ).label
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "work-order-detail-btn",
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewTeambitionDetail(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("span", [_vm._v("工单状态")]),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "点击查看Teambiton工单详情",
                                placement: "top",
                                effect: "light",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-info" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "created", label: "创建时间", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status !== 1 && !scope.row.is_edited
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      plain: "",
                                      icon: "el-icon-printer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printBarCode(
                                          scope.row.waybill_num
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("打印")]
                                )
                              : _vm._e(),
                            scope.row.status !== 1 && !scope.row.is_edited
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.processWayBill(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("快速处理")]
                                )
                              : _vm._e(),
                            scope.row.status_shelf == 1 && !scope.row.is_edited
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "warning",
                                      size: "mini",
                                      plain: "",
                                      icon: "el-icon-printer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.outShelf(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("下架")]
                                )
                              : _vm._e(),
                            scope.row.is_edited
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button-group",
                                      [
                                        scope.row.is_edited
                                          ? _c(
                                              "z-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.saveUserCode(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-check",
                                                }),
                                                _vm._v("保存"),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "z-button",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelEdit(scope)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-close",
                                            }),
                                            _vm._v("取消"),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("z-pagination", {
                attrs: {
                  total: _vm.pageConfig.total,
                  "page-size": _vm.pageConfig.size,
                  "current-page": _vm.pageConfig.current_page,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.form.item.waybill,
            visible: _vm.form.visible,
            width: "480px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.form, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.processForm,
              },
            },
            [
              _c(
                "div",
                { staticClass: "inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "处理方式", prop: "process_method" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.process_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "process_method", $$v)
                            },
                            expression: "form.process_method",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: 1,
                                disabled: _vm.form.item.status == 4,
                              },
                            },
                            [_vm._v("正常作业")]
                          ),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("退回"),
                          ]),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: 3,
                                disabled: _vm.form.item.status == 4,
                              },
                            },
                            [_vm._v("其他")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.process_method == 2
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "inline-from" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "快递公司",
                              prop: "express_company",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "select",
                                attrs: {
                                  filterable: "",
                                  "allow-create": "",
                                  placeholder: "请选择快递公司",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.express_company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "express_company", $$v)
                                  },
                                  expression: "form.express_company",
                                },
                              },
                              _vm._l(_vm.logisticsList, function (item) {
                                return _c("el-option", {
                                  key: item.key,
                                  attrs: { label: item.name, value: item.name },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-from" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "快递单号",
                              prop: "express_number",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "off",
                                placeholder: "请输入快递单号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.express_number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "express_number", $$v)
                                },
                                expression: "form.express_number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _c(
                    "div",
                    { staticClass: "inline-from" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "remark-input",
                          attrs: { label: "处理结果", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              autocomplete: "off",
                              placeholder:
                                "请输入处理结果,如作业的GLP单号或者其他备注信息",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.form.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleProcess },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }